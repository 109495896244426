import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './guide-style.less';
import { useVisibleAnimation } from './guide-compose';
import { ComponentStatementRender } from '../util';


export const GuideStep = (props) => {
    const {
        contentStyle,
        lineStyle,
        circleStyle
    } = useVisibleAnimation(props);

    return (
        <div className={cn('guide-step', props.placement)}>
            <div className="guide-step__main" style={contentStyle}>
                <div className="guide-step__prefix">
                    <ComponentStatementRender component={props.prefix} />
                </div>
                <div className="guide-step__container">
                    <ComponentStatementRender component={props.title}>
                        <h3 className="guide-step__title">{props.title}</h3>
                    </ComponentStatementRender>
                    <ComponentStatementRender component={props.content}>
                        <div className="guide-step__content">{props.content}</div>
                    </ComponentStatementRender>
                    <ComponentStatementRender component={props.footer}>
                        <div className="guide-step__footer">
                            {
                                props.showSteps &&
                                    <span className="guide-step__footer-steps">
                                        {
                                            props.stepTextFormat(props.step, props.stepCount)
                                        }
                                    </span>
                            }
                            {
                                props.showSkip &&
                                    <button
                                        onClick={props.onSkipAll}
                                        className="guide-step__footer-skip"
                                    >
                                        {props.skipText}
                                    </button>
                            }

                            <button
                                onClick={props.onNext}
                                className="guide-step__footer-next"
                            >
                                {props.nextBtnText}
                            </button>
                        </div>
                    </ComponentStatementRender>
                </div>
            </div>
            <div className="guide-step__line" style={lineStyle}></div>
            <div className="guide-step__circle" style={circleStyle}></div>
        </div>
    );
};



GuideStep.propTypes = {
    step: PropTypes.number,
    stepCount: PropTypes.number,
    targetWidth: PropTypes.number.isRequired, // 目标元素的宽度
    targetHeight: PropTypes.number.isRequired, // 目标元素的高度

    /** @docs 展示位置 */
    placement: PropTypes.string,

    /** @docs 标题 */
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object
    ]),
    /** @docs 内容 */
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object
    ]),
    /** @docs 左侧内容组件 */
    prefix: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),

    /** @docs 页脚内容组件，当传入该子段时页脚相关配置无效 */
    footer: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),

    /** @docs 展示当前步数 */
    showSteps: PropTypes.bool,
    /** @docs 当前步数文案formatter */
    stepTextFormat: PropTypes.func,

    /** @docs 是否展示跳过按钮 */
    showSkip: PropTypes.bool,
    /** @docs 跳过按钮文字 */
    skipText: PropTypes.string,

    /** @docs 下一步按钮文案 */
    nextBtnText: PropTypes.string,

    /** @docs 点击跳过全部回调 */
    onSkipAll: PropTypes.func,
    /** @docs 点击下一步回调 */
    onNext: PropTypes.func
};

GuideStep.defaultProps = {
    placement: 'rightTop',
    title: '',
    content: '',
    showSteps: true,
    stepTextFormat: (step, count) => `${step}/${count}`,
    showSkip: true,
    skipText: '跳过',
    nextBtnText: '下一步',

    onSkipAll: () => {},
    onNext: () => {}
};
