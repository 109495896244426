import { eventBus } from './bus';
import debounce from 'lodash.debounce';

let isBinding = false;

const onResizeFn = debounce(() => {
  eventBus.emit('window-resize');
}, 50);

export function runResizeWatcher() {
  if (isBinding) return;
  isBinding = true;
  window.addEventListener('resize', onResizeFn);
}

export function stopResizeWatcher() {
  isBinding = false;
  window.removeEventListener('resize', onResizeFn);
}
