import React from 'react';
import PropTypes from 'prop-types';
import { ComponentStatementRender } from '../util';
import './modal-style.less';

export const ModalStep = (props) => {
    return <div className="modal-step" style={{ width: props.width }}>
        <div className="modal-step__cover">
            <ComponentStatementRender component={props.cover} />
        </div>
        <ComponentStatementRender component={props.body}>
            <div className="modal-step__body">
                <ComponentStatementRender component={props.title}>
                    <h3 className="modal-step__title">
                        {props.title}
                    </h3>
                </ComponentStatementRender>
                <ComponentStatementRender component={props.content}>
                    <h3 className="modal-step__content">
                        {props.content}
                    </h3>
                </ComponentStatementRender>
            </div>
        </ComponentStatementRender>
        <ComponentStatementRender component={props.footer}>
            <div className="modal-step__footer">
                <button className="modal-step__footer-btn" onClick={props.onNext}>
                    {props.nextBtnText}
                </button>
            </div>
        </ComponentStatementRender>
        {
            props.closable &&
                <button className="modal-step__close" onClick={props.onClose}>
                    <ComponentStatementRender component={props.closeIcon}>
                        <span className="modal-step__close-icon"></span>
                    </ComponentStatementRender>
                </button>
        }
    </div>;
};

ModalStep.propTypes = {
    /** @docs 宽度 */
    width: PropTypes.number.isRequired,

    /** @docs 头部组件 */
    cover: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),

    /** @docs 是否展示关闭按钮 */
    closable: PropTypes.bool,

    /** @docs 关闭按钮组件 */
    closeIcon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),

    /** @docs 内容组件 */
    body: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    /** @docs 标题 */
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object
    ]),
    /** @docs 内容文字 */
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),

    /** @docs 页脚内容组件 */
    footer: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),

    /** @docs 下一步按钮文案 */
    nextBtnText: PropTypes.string,

    /** @docs 点击关闭按钮的回调 */
    onClose: PropTypes.func,

    /** @docs 点击下一步回调 */
    onNext: PropTypes.func
};

ModalStep.defaultProps = {
    width: 640,
    title: '',
    conten: '',
    nextBtnText: '下一步',
    onClose: () => {},
    onClick: () => {}
};