import { render } from 'react-dom';
import { eventBus } from './bus';
import { createElement } from 'react';
import { GuideStep } from './components/guide';
import { ModalStep } from './components/modal';
import { getStepCount } from './head';
import { reviseElementRect } from './util';
import { runResizeWatcher } from './resize';

/**
 * 渲染引导类元素
 */
function renderGuide(control, guideHead) {
    const targetEl = document.querySelector(guideHead.selector);

    // 如果没有找到制定的目标元素
    // 结束渲染
    if (!targetEl) {
        eventBus.emit(
            'error',
            `can not find element: ${guideHead.selector}`,
            guideHead
        );
        return;
    }

    // 获取校正后的位置数据
    // 将目标元素滚动到可视范围内
    const { width, height, left, top, osLeft } = reviseElementRect(targetEl, guideHead.padding);
    const scrollTop = top + height - window.innerHeight / 2;
    window.scrollTo(osLeft, scrollTop);

    control.show({ width, height, left, top }, () => {
        render(createElement(GuideStep, {
            ...guideHead.guideProps,
            step: guideHead.step,
            stepCount: getStepCount(),
            showSteps: !!guideHead.step,
            targetWidth: width,
            targetHeight: height,
            onSkipAll: () => eventBus.emit('skip-all'),
            onNext: () => eventBus.emit('next', guideHead)
        }), control.helperLayer);

        eventBus.emit('show', guideHead);
    });
}

/**
 * 渲染弹窗类元素
 */
function renderModal(control, guideHead) {
    control.show({
        width: 0,
        height: 0,
        left: window.innerWidth / 2,
        top: window.innerHeight / 2
    }, () => {
        eventBus.emit('show', guideHead);
    });

    render(createElement(ModalStep, {
        ...guideHead.guideProps,
        onClose: () => {
            eventBus.emit('next', guideHead);
            eventBus.emit('close', guideHead);
        },
        onNext: () => eventBus.emit('next', guideHead)
    }), control.helperLayer);
}

export function renderStep(control, guideHead) {
    if (guideHead.type === 'guide') {
        renderGuide(control, guideHead);
        runResizeWatcher();
    }

    if (guideHead.type === 'modal') {
        renderModal(control, guideHead);
    }
}