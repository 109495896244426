import { createElement } from 'react';

// 添加一些简单的样式，不会transform前缀;
export function setSimpleStyle(el, styles) {
    Object.keys(styles).forEach(key => {
        el.style[key] = styles[key];
    });
}

// 修正宽高位置
export function reviseElementRect(el, padding = { v: 0, h: 0 }) {
    const osTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    const osLeft = document.documentElement.scrollLeft || document.body.scrollLeft || 0;
    const { width: w, height: h, left: l, top: t } = el.getBoundingClientRect();

    // 校正位置及宽高
    const width  = w + padding.h * 2;
    const height = h + padding.v * 2;
    const top    = t + osTop - padding.v;
    const left   = l + osLeft - padding.h;

    return {
        width,
        height,
        top,
        left,
        osLeft,
        osTop
    };
}

export function ComponentStatementRender({ component, children = null }) {
    return typeof component === 'function'
        ? createElement(component)
        : typeof component === 'object'
            ? component
            : children;
}


const raf = window.requestAnimationFrame ? window.requestAnimationFrame.bind(window) : setTimeout;

export function nextTick(fn) {
  raf(() => {
    raf(fn);
  });
}
