import { nextTick, setSimpleStyle } from './util';

const ANIMATION_CALLBACK_KEY = '__animationEndCallback';
const ROOT_APPENDED_KEY = '__isAppendedToDocument';
/**
 * 默认根结点样式
 */
const DEFAULT_ROOT_STYLE = {
    zIndex: 99999999,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};

/**
 * 默认选框样式
 */
const DEFAULT_TARGET_STYLE = {
    zIndex: 100,
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '0',
    height: '0',
    boxShadow: 'rgb(33 33 33 / 50%) 0px 0px 0px 5000px',
    webKitBoxShadow: 'rgb(33 33 33 / 50%) 0px 0px 0px 5000px',
    transition: 'all .3s ease-out',
    webKitTransition: 'all .3s ease-out',
    borderRadius: '4px',
    opacity: 0,
};

/**
 * 默认弹窗样式
 */
const DEFAULT_HELPER_STYLE = {
    zIndex: 200,
    position: 'absolute',
    width: '100%',
    height: '100%'
};

/**
 * 默认底板样式
 */
const DEFAULT_CURSOREL_STYLE = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

function initRootStyle(rootEl) {
    setSimpleStyle(rootEl, DEFAULT_ROOT_STYLE);
}

function initCursorStyle(cursorEl) {
    setSimpleStyle(cursorEl, DEFAULT_CURSOREL_STYLE);
}

function initTargetStyle(targetEl) {
    setSimpleStyle(targetEl, DEFAULT_TARGET_STYLE);
}

function initHelperStyle(helperEl) {
    setSimpleStyle(helperEl, DEFAULT_HELPER_STYLE);
}

function onAnimationEnd(el, callback) {
    el[ANIMATION_CALLBACK_KEY] = callback;
}

function addAnimationendEvent(el) {
    el.addEventListener('transitionend', function () {
        if (this[ANIMATION_CALLBACK_KEY]) {
            this[ANIMATION_CALLBACK_KEY]();
            this[ANIMATION_CALLBACK_KEY] = null;
        }
    });
}

function appendWithDocument(el) {
    if (!el[ROOT_APPENDED_KEY]) {
        el[ROOT_APPENDED_KEY] = true;
        document.body.appendChild(el);
    }
}

function removeWithDocument(el) {
    if (el[ROOT_APPENDED_KEY]) {
        el[ROOT_APPENDED_KEY] = false;
        document.body.removeChild(el);
    }
}

export function createRootControl() {
    // 初始化node节点
    const rootLayer = document.createElement('div');
    const targetLayer = document.createElement('div');
    const helperLayer = document.createElement('div');
    const cursorLayer = document.createElement('div');

    // 初始化样式
    initRootStyle(rootLayer);
    initCursorStyle(cursorLayer);
    initTargetStyle(targetLayer);
    initHelperStyle(helperLayer);

    // 注册动画结束回调
    addAnimationendEvent(targetLayer);


    rootLayer.appendChild(cursorLayer);
    rootLayer.appendChild(targetLayer);
    targetLayer.appendChild(helperLayer);

    /**
     * 重置选框位置
     */
    function resetTargetRect({ top, left, width, height }) {
        setSimpleStyle(targetLayer, {
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`,
            height: `${height}px`,
        });
    }

    /**
     * show elements
     */
    function show({ top, left, width, height }, callback) {
        appendWithDocument(rootLayer);
        nextTick(() => {
            onAnimationEnd(targetLayer, callback);
            resetTargetRect({ top, left, width, height });
            setSimpleStyle(targetLayer, {
                opacity: 1,
            });
        });
    }

    /**
     * hidden elements
     */
    function hide(callback) {
        onAnimationEnd(targetLayer, () => {
            callback && callback();
            removeWithDocument(rootLayer);
        });
        setSimpleStyle(targetLayer, {
            opacity: 0
        });
    }

    /**
     * 是否在展示中
     */
    function isVisible() {
        return rootLayer[ROOT_APPENDED_KEY];
    }

    return {
        show,
        hide,
        rootLayer,
        targetLayer,
        helperLayer,
        isVisible,
        resetTargetRect
    };
}
