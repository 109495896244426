import { useEffect, useState } from 'react';

const CIRCLE_RADIUS = 5; // 圆点半径
const VERTICAL_OFFSET = 34; // 竖向偏移量
const HORIZONTAL_OFFSET = 118; // 横向偏移量


const useRightTopStyle = ({ targetHeight }) => {
    const [lineStyle, updateLineStyle] = useState({
        left: 0,
        width: 0,
        height: 1,
        top: targetHeight / 2
    });
    const [circleStyle, updateCircleStyle] = useState({
        left: 0,
        top: Math.ceil(targetHeight / 2) - CIRCLE_RADIUS
    });

    useEffect(() => {
        updateLineStyle(styles => ({ ...styles, width: HORIZONTAL_OFFSET }));
        updateCircleStyle(styles => ({ ...styles, left: HORIZONTAL_OFFSET }));
    }, []);

    return { lineStyle, circleStyle };
};


const useBottomRightStyle = ({ targetWidth }) => {
    const [lineStyle, updateLineStyle] = useState({
        top: 0,
        width: 1,
        height: 0,
        right: targetWidth / 2,
    });

    const [circleStyle, updateCircleStyle] = useState({
        top: 0,
        right: Math.ceil(targetWidth / 2) - CIRCLE_RADIUS
    });

    useEffect(() => {
        updateLineStyle(styles => ({ ...styles, height: VERTICAL_OFFSET }));
        updateCircleStyle(styles => ({ ...styles, top: VERTICAL_OFFSET }));
    }, []);

    return { lineStyle, circleStyle };
};

/**
 * guide背景窗动画样式
 */
const useContentStyle = () => {
    const [contentStyle, updateContentStyle] = useState({ opacity: 0 });

    useEffect(() => {
        updateContentStyle({ opacity: 1 });
    }, []);

    return contentStyle;
};


const styleHooksMap = {
    'rightTop': useRightTopStyle,
    'bottomRight': useBottomRightStyle
};

export function useVisibleAnimation({ targetWidth, targetHeight, placement }) {
    const contentStyle = useContentStyle();
    const { lineStyle, circleStyle } = styleHooksMap[placement]({ targetWidth, targetHeight });

    return { contentStyle, lineStyle, circleStyle };
}